@media (prefers-color-scheme: dark) {
	:root {
		--primary-background: #1f1f1f;
		--secondary-background: #2f2f2f;
		--text: #fff;
	}
}

@media (prefers-color-scheme: light) {
	:root {
		--primary-background: #fafafa;
		--secondary-background: #eaeaea;
		--text: #000;
	}
}

/* Google styled navbar */
.navbar {
	background-color: var(--secondary-background);
	border-bottom: 1px solid var(--primary-background);
	border-top: 1px solid var(--primary-background);
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
	padding: 0;
	top: 0;
	position: sticky;
	height: 60px;
	width: calc(100% - 1px);
	z-index: 1030;
}

.navbar-items a {
	color: var(--text);
	text-decoration: none;
}

.navbar-items {
	/* Hacky way to make the buttons take up the entire navbar */
	height: 100%;
	padding: 0;
	margin: -1px;
	/* until here */
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	text-decoration: none;
	list-style: none;
}

.navbar-btn {
	display: flex;
	border-right: 1px solid var(--primary-background);
	padding: 5px;
	border-radius: 2px / 10px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 763px) {
	.navbar {
		display: none;
		height: 500px;
	}
	.navbar-items {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.navbar-btn {
		border-right: none;
		border-bottom: 1px solid var(--primary-background);
		border-radius: 10px / 2px;
		width: 100%;
		height: 100%;
	}
}
