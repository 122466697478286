body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
		"Courier New", monospace;
	font-size: 13px;
	padding: 5px;
	color: var(--text-code);
	background-color: #0006;
}
